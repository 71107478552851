import { SECOND } from "@shared/models/constants/time.constant";

export const CErrorPullingCreateTeenContext = {
  ONBOARDING_PULLING_CREATE_TEEN: "ONBOARDING_PULLING_CREATE_TEEN",
} as const;

export const CPollingCreateTeenValues = {
  MAX_TRIES: 180,
  API_CALL_INTERVAL: 2 * SECOND,
} as const;
