export const COnboardingTypeStates = {
    PENDING: "pending",
    KYC_APPROVED : "kyc_approved",
    COMPLETED : "completed"
} as const;
export const DEFAULT_CARD = 'mo_faces'
export const DEFAULT_CARD_GALILEO = 'mo_gogh';
export const COnOnboardingFirstWayStorage = 'isFirstWay';
export const COnboardingIsStarted = 'onboardingIsStarted';
export const CFundingPath = 'funding';
export const CRewardPath = 'reward';
export const CFundingUrl = '/funding';
export const CRequestCardPath = '/onboarding/funding/request-card';
export const CRequestCardUrl = '/request-card';
export const CRequestCardFullPath = '/parents/onboarding/request-card';
export const CCheckoutFullPath = '/parents/onboarding/funding/checkout';
export const CCheckoutOnboardingFullPath = '/onboarding/funding/checkout';
export const CFundingOnboardingFullPath = '/onboarding/funding';
export const CDownloadOnboardingFullPath = '/onboarding/download-app';
export const CWelcomeOnboardingFullPath = '/onboarding/welcome';
export const CWelcomeCardThemesOnboardingFullPath = '/onboarding/welcome/card-themes';
export const CDownloadAppUrl = '/download-app';
export const CDownloadLastAppUrl = '/onboarding/download-app?isLast=true';
export const CParentHome = '/parents/home';
export const CReward = '/reward';
export const CKyc = '/kyc';
export const CAddTeen ='/add-teen';
export const CAddTeenContactInfo ='create-teen-contact-info';
export const CAddTeenDetail ='teen-detail';
export const CTeenDetail='/teen-detail';
export const COneMoreStepAheadUrl = '/one-more-step-ahead';
export const CRewardTrophySvg  = 'reward_trophy_white.svg';
export const COneMoreStepAheadLastSegmentUrl = 'one-more-step-ahead';
export const COnboardingDirectTransfer = 'parents/onboarding/funding/direct-transfer'
export const COnboardingKycPlay = 'kyc/plaid'
export const COnboardingKycNotStartedAddressPlay = '/onboarding/kyc/not-started/address'
